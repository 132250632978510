import { Nav, Navbar, Container, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import './style.scss';
import hsiLogo from 'assets/images/hsi.png';
import ulapLogo from 'assets/images/ulap.png';
import nicpLogo from 'assets/images/nicp.png';
import logo from 'assets/images/DILG LOGO.png';
import usaidLogo from 'assets/images/usaid.png';
import crayonLogo from 'assets/images/new crayon.png';
import ygoalLogo from 'assets/images/ygoal.png';

const Topbar = ({ setPage }) => {
  const navigate = useNavigate();

  // Function to handle navigation
  const handleNavigation = () => {
    setPage(1); // This will set the page to 1
    navigate('/'); // Redirect to home page
  };

  return (
    <div className='topbar'>
      <Navbar expand='lg' className='shadow-sm'>
        <Container>
          <Navbar.Brand onClick={handleNavigation} style={{ cursor: 'pointer' }}>
            <Image alt='DILG Logo' src={logo} className='d-inline-block align-top' />
            Ease of doing business forum 2024
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='center-nav'>
              <Nav.Link onClick={handleNavigation} style={{ cursor: 'pointer' }} className='active'>
                Home
              </Nav.Link>
              {/* <Nav.Link>Sessions</Nav.Link>
              <Nav.Link>Speakers</Nav.Link> */}
            </Nav>
            <Nav className='end-nav'>
              <a href='https://nicp.org.ph/' target='_blank' rel='noopener noreferrer'>
                <Image alt='nicp logo' src={nicpLogo} className='d-inline-block align-top' />
              </a>
              <a href='https://www.ulap.net.ph/' target='_blank' rel='noopener noreferrer'>
                <Image alt='Ulap logo' src={ulapLogo} className='d-inline-block align-top' />
              </a>
              <a href='https://www.usaid.gov/' target='_blank' rel='noopener noreferrer'>
                <Image alt='usaid logo' src={usaidLogo} className='d-inline-block align-top' />
              </a>
              <a href='https://www.crayon.com/ph/' target='_blank' rel='noopener noreferrer'>   
                <Image alt='crayon logo' src={crayonLogo} className='d-inline-block align-top' />
              </a>
              <a href='https://ygoal-org.my.canva.site/works-landing' target='_blank' rel='noopener noreferrer'>   
                <Image alt='ygoal logo' src={ygoalLogo} className='d-inline-block align-top' />
              </a>              
              <a href='https://highlysucceed.com/services.html' target='_blank' rel='noopener noreferrer'>     
                <Image alt='hsi logo' src={hsiLogo} className='d-inline-block align-top' />
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Topbar;
