// import core and vendor packages below
import { Container, Image, Row, Col } from 'react-bootstrap';

// import components below

// import style below
import './style.scss';

// import images
import philLogo from 'assets/images/ph-new-seal.png';
import { ReactComponent as FBLogo } from 'assets/images/facebook.svg';
import { ReactComponent as TwitterLogo } from 'assets/images/twitter.svg';

// main component
const Footer = () => {
  return (
    <>
      <div className='footer'>
        <Container>
          <Row>
            <Col className='phil-seal'>
              <Image alt='Philippines new seal' className='d-inline-block' src={philLogo} />
            </Col>
            <Col className='footer-list'>
              <h3>Republic of the Philippines</h3>

              <p>All content in the public domain unless otherwise stated.</p>
            </Col>
            <Col className='footer-list'>
              <h3>About GOVPH</h3>

              <p>
                Learn more about the Philippine government, its structure, how government works and
                the people behind it.
              </p>

              <ul>
                <li><a href='https://www.officialgazette.gov.ph/' target='blank' rel='noopener noreferrer'>Official Gazette</a></li>
                <li><a href='https://data.gov.ph/index/home' target='blank' rel='noopener noreferrer'>Open Data Portal</a></li>
                <li><a href='https://docs.google.com/forms/d/e/1FAIpQLSfwbvoukcYX7RI89UrVuY4ZzkOfQdtybKYEHd3mBA2sZISPRg/viewform' target='blank' rel='noopener noreferrer'>Send us your feedback</a></li>
              </ul>
            </Col>
            <Col className='footer-list'>
              <h3>About GOVPH</h3>

              <ul>
                <li><a href='https://www.op.gov.ph' target='_blank' rel='noopener noreferrer'>Office of the President</a></li>
                <li><a href='https://www.ovp.gov.ph' target='_blank' rel='noopener noreferrer'>Office of the Vice President</a></li>
                <li><a href='https://www.senate.gov.ph' target='_blank' rel='noopener noreferrer'>Senate of the Philippines</a></li>
                <li><a href='https://www.congress.gov.ph' target='_blank' rel='noopener noreferrer'>House of Representatives</a></li>
                <li><a href='https://sc.judiciary.gov.ph' target='_blank' rel='noopener noreferrer'>Supreme Court</a></li>
                <li><a href='https://ca.judiciary.gov.ph' target='_blank' rel='noopener noreferrer'>Court of Appeals</a></li>
                <li><a href='https://sb.judiciary.gov.ph' target='_blank' rel='noopener noreferrer'>Sandiganbayan</a></li>
                <li><a href='https://www.gppb.gov.ph' target='_blank' rel='noopener noreferrer'>Government Procurement</a></li>
                <li><a href='https://www.gppb.gov.ph/government-procurement-policy-board/' target='_blank' rel='noopener noreferrer'>Policy Board</a></li>
              </ul>
            </Col>

            <Col className='footer-list'>
              <h3>Connect with us Be updated about DILG</h3>

              <p className='icon'>
                <a href='https://www.facebook.com/dilg.philippines' target='_blank' rel='noopener noreferrer'>  
                  <FBLogo />
                </a>
                <a href='https://www.facebook.com/dilg.philippines' target='_blank' rel='noopener noreferrer'>
                  Like us on Facebook
                </a>
              </p>
              <p className='icon'>
                <a href='https://x.com/DILGPhilippines' target='_blank' rel='noopener noreferrer'>  
                  <TwitterLogo />
                </a>
                <a href='https://x.com/DILGPhilippines' target='_blank' rel='noopener noreferrer'>
                  Follow us on Twitter
                </a>
              </p>
            </Col>

          </Row>
        </Container>
      </div>

      <footer>
        <Container>
          <Row>
            <Col className='footer-container'>
              <p>Department of the Interior and Local Government</p>
              <p>
                DILG-NAPOLCOM Center, EDSA, corner Quezon Avenue, West Triangle, Quezon City,
                Philippines 1104
              </p>
              <p>© 2013 DILG All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
