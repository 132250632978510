import React, { useState } from 'react';

import Main from './components/Main';
import Topbar from 'components/Layout/Topbar';
import Footer from 'components/Layout/Footer';

const HomePage = () => {
  const [page, setPage] = useState(1); // Lift the page state here

  return (
    <div className='home'>
      {/* Pass setPage to Topbar */}
      <Topbar setPage={setPage} />
      
      {/* Pass page and setPage to Main */}
      <Main page={page} setPage={setPage} />

      <Footer />
    </div>
  );
};

export default HomePage;
