import React, { useState } from 'react';

// components
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import ConditionalRender from 'components/ConditionalRender';

// style
import './Main.scss';

const initialState = {
  id: {},
  city: '',
  chief: '',
  region: '',
  gender: '',
  cluster: '',
  lastname: '',
  province: '',
  memberof: '',
  firstname: '',
  promotion: '',
  designation: '',
  phonenumber: '',
  othermember: '',
  emailaddress: '',
  mobilenumber: '',
  middleinitial: '',
  representative: '',
};

const Main = ({ page, setPage }) => {
  const [userData, setUserData] = useState(initialState);

  const toRender = [
    {
      condition: page === 1,
      component: PageOne,
    },
    {
      condition: page === 2,
      component: PageTwo,
    },
  ];

  return (
    <>
      {toRender.map(({ condition, component: Component }, idx) => (
        <ConditionalRender
          key={idx}
          condition={condition}
          renderIf={
            <Component
              setPage={setPage}
              userData={userData}
              setUserData={setUserData}
              initialState={initialState}
            />
          }
        />
      ))}
    </>
  );
};

export default Main;
