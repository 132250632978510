/* eslint-disable no-unused-vars */
import axios from 'axios';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Image, Form, Button } from 'react-bootstrap';
import { regions, provinces, cities } from 'select-philippines-address';

const PageTwo = ({ userData, setUserData, setPage, initialState }) => {
  const [formData, setFormData] = useState({
    regions: [],
    provinces: [],
    cities: [],
    isSubmitting: false,
  });

  const handleChange = (key) => (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  
    if (key === 'region') {
      const selectedIndex = e.target.options.selectedIndex;
      provinces(e.target.options[selectedIndex].getAttribute('data-key')).then((provinces) => {
        setFormData((prev) => {
          return {
            ...prev,
            provinces: provinces,
            cities: [],
          };
        });

        setUserData((prev) => {
          return {
            ...prev,
            province: '',
            city: '',
          };
        });
      });
    }

    if (key === 'province') {
      const selectedIndex = e.target.options.selectedIndex;

      cities(e.target.options[selectedIndex].getAttribute('data-key')).then((cities) => {
        setFormData((prev) => {
          return {
            ...prev,
            cities: cities,
          };
        });

        setUserData((prev) => {
          return {
            ...prev,
            city: '',
          };
        });
      });
    }
  };

  const handleFileUpload = (e) => {
    // eslint-disable-next-line prefer-const
    let docuArr = [];

    Array.from(e.target.files).forEach((file) => {
      docuArr.push(file);
    });

    if (e?.target?.files[0]) {
      setUserData((prevState) => {
        return {
          ...prevState,
          id: e.target.files[0],
        };
      });
    }
  };

  const handleCancel = () => {
    setPage(1);
  };

  const handleClear = () => {
    setUserData(initialState);
  };

  const handleRegister = () => {
    const checkValues = [
      userData?.lastname,
      userData?.firstname,
      userData?.middleinitial,
      userData?.nickname,
      userData?.designation,
      userData?.emailaddress,
      userData?.mobilenumber,
      userData?.gender,
      userData?.region,
      userData?.province,
      userData?.city,
      userData?.memberof,
      userData?.chief,
      userData?.representative,
      userData?.foodrestriction,
      userData?.buildingofficial,
      // userData?.promotion,
    ];

    const result = checkValues.filter((data) => data === '');
    if (result.length > 0) {
      toast.error('Please fill in the empty fields');
      return;
    }

    if (userData?.memberof === '4' && userData?.othermember === '') {
      toast.error('Please fill in the others field');
      return;
    }

    if (userData?.id?.name === undefined || userData?.id?.name === null) {
      toast.error('Please upload an ID');
      return;
    }

    toast.info('Submitting registration, please wait.');

    const formData = new FormData();

    formData.append('last_name', userData?.lastname);
    formData.append('first_name', userData?.firstname);
    formData.append('middle_initial', userData?.middleinitial);
    formData.append('designation', userData?.designation);
    formData.append('email', userData?.emailaddress);
    formData.append('nickname', userData?.nickname);
    formData.append('mobile', userData?.mobilenumber);
    formData.append('gender', userData?.gender);
    formData.append('cluster', '2');
    formData.append('region', userData?.region);
    formData.append('province', userData?.province);
    formData.append('city', userData?.city);
    formData.append('food_restrictions', userData?.foodrestriction);
    formData.append('is_building_official', userData?.buildingofficial);

    switch (userData?.memberof) {
      case '1':
        formData.append('is_person_disabled', '1');
        formData.append('is_senior', '0');
        formData.append('is_indigenous', '0');
        break;
      case '2':
        formData.append('is_person_disabled', '0');
        formData.append('is_senior', '1');
        formData.append('is_indigenous', '0');
        break;

      case '3':
        formData.append('is_person_disabled', '0');
        formData.append('is_senior', '0');
        formData.append('is_indigenous', '1');
        break;
      case '4':
        formData.append('is_person_disabled', '0');
        formData.append('is_senior', '0');
        formData.append('is_indigenous', '0');
        formData.append('other_member', userData?.othermember);
        break;
      case '5':
        formData.append('is_person_disabled', '0');
        formData.append('is_senior', '0');
        formData.append('is_indigenous', '0');
        formData.append('other_member', userData?.othermember);
        break;
      default:
        break;
    }

    formData.append('valid_id', userData?.id);
    formData.append('is_chief_executive', userData?.chief);
    formData.append('is_authorized_representative_lgu', userData?.representative);
    // formData.append('is_allow_contact', userData?.promotion);
    formData.append('is_bplo', userData?.isOfficer);

    setFormData((prev) => {
      return {
        ...prev,
        isSubmitting: true,
      };
    });

    axios({
      method: 'post',
      url: 'api/v1/forum-registration/register',
      baseURL: process.env.REACT_APP_API_BASE_URL,
      data: formData,
    })
      .then((response) => {
        toast.success(
          process.env.REACT_APP_SUBMIT_CONFIRMATION || 'Registration submitted. Please wait for our e-mail confirmation of your registration status.',
        );
        handleClear();
        setPage(1);

        window.scrollTo({ top: 0, behavior: 'smooth' });
      })
      .catch((err) => {
        console.log(err.response.data.errors);
        err.response?.data?.errors?.map((data) => {
          toast.error(data.detail);
        });
      });

    setFormData((prev) => {
      return {
        ...prev,
        isSubmitting: false,
      };
    });
  };

  useEffect(() => {
    regions().then((region) => {
      setFormData((prev) => {
        return {
          ...prev,
          regions: region,
        };
      });
    });
  }, []);
  
  useEffect(() => {
    // include only Region VI, VII, and VIII
    regions().then((regionList) => {
      const filteredRegions = regionList.filter((region) =>
        ['06', '07', '08'].includes(region.region_code));
      setFormData((prev) => ({
        ...prev,
        regions: filteredRegions,
      }));
    });
  }, []);
  
  return (
    <>
      <div className='more-information'>
        <Container>
          <Row>
            <Col className='information-container'>
              <h2>finish your event registration</h2>

              <Form className='final-form'>
                <Row className='mb-5'>
                  <h4>Address information</h4>

                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Region <b>*</b>
                      </Form.Label>
                      <Form.Select
                        value={userData?.region}
                        onChange={handleChange('region')}
                        aria-label='Select Region'
                      >
                        <option value='' hidden>
                          Select Region
                        </option>
                        {formData?.regions.map((data, idx) => {
                          return (
                            <option
                              key={data?.region_code}
                              data-key={data?.region_code}
                              value={data?.region_name}
                            >
                              {data?.region_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Province <b>*</b>
                      </Form.Label>
                      <Form.Select
                        value={userData?.province}
                        onChange={handleChange('province')}
                        aria-label='Select Province'
                      >
                        <option value='' hidden>
                          Select Province
                        </option>
                        {formData?.provinces.map((data, idx) => {
                          return (
                            <option
                              key={data?.province_code}
                              data-key={data?.province_code}
                              value={data?.province_name}
                            >
                              {data?.province_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        City/Municipality <b>*</b>
                      </Form.Label>
                      <Form.Select
                        value={userData?.city}
                        onChange={handleChange('city')}
                        aria-label='Select City'
                      >
                        <option value='' hidden>
                          Select City/Municipality
                        </option>
                        {formData?.cities.map((data, idx) => {
                          return (
                            <option key={idx} value={data?.city_name}>
                              {data?.city_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-2'>
                  <h4>USER INFORMATION</h4>

                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Last Name <b>*</b>
                      </Form.Label>
                      <Form.Control
                        value={userData?.lastname}
                        onChange={handleChange('lastname')}
                        placeholder='Enter Last Name'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        First Name <b>*</b>
                      </Form.Label>
                      <Form.Control
                        value={userData?.firstname}
                        onChange={handleChange('firstname')}
                        placeholder='Enter First Name'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Middle Initial <b>*</b>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={userData?.middleinitial}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[A-Za-z]{0,5}$/.test(value)) {
                            handleChange('middleinitial')(e);
                          }
                        }}
                        placeholder='Enter Middle Initial'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-2'>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Nickname <b>*</b>
                      </Form.Label>
                      <Form.Control
                        value={userData?.nickname}
                        onChange={handleChange('nickname')}
                        placeholder='Enter Nickname'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Designation <b>*</b>
                      </Form.Label>
                      <Form.Control
                        value={userData?.designation}
                        onChange={handleChange('designation')}
                        placeholder='Enter Designation'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Email Address <b>*</b>
                      </Form.Label>
                      <Form.Control
                        type='email'
                        value={userData?.emailaddress}
                        onChange={handleChange('emailaddress')}
                        placeholder='Enter Email Address'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Mobile Number <b>*</b>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={userData?.mobilenumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d{0,11}$/.test(value)) {
                            handleChange('mobilenumber')(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (
                            !['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key) &&
                            isNaN(Number(e.key))
                          ) {
                            e.preventDefault();
                          }
                        }}
                        placeholder='09xxxxxxxxx'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Gender <b>*</b>
                      </Form.Label>
                      <Form.Select
                        value={userData?.gender}
                        onChange={handleChange('gender')}
                        aria-label='Select gender'
                      >
                        <option value='' hidden>
                          Select Gender
                        </option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col></Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group-check'>
                      <Form.Label>
                        Are you a member of? <b>*</b>
                      </Form.Label>

                      <div className='selection'>
                        <Form.Check
                          inline
                          label='Person with Disability'
                          name='member'
                          value='1'
                          type={'radio'}
                          id={'member'}
                          checked={userData?.memberof === '1'}
                          onChange={handleChange('memberof')}
                        />
                        <Form.Check
                          inline
                          label='Senior Citizen'
                          name='member'
                          value='2'
                          type={'radio'}
                          id={'member'}
                          checked={userData?.memberof === '2'}
                          onChange={handleChange('memberof')}
                        />
                        <Form.Check
                          inline
                          label='Indigenous People'
                          name='member'
                          value='3'
                          type={'radio'}
                          id={'member'}
                          checked={userData?.memberof === '3'}
                          onChange={handleChange('memberof')}
                        />
                        <Form.Check
                          inline
                          label='Others'
                          name='member'
                          value='4'
                          type={'radio'}
                          id={'member'}
                          checked={userData?.memberof === '4'}
                          onChange={handleChange('memberof')}
                        />
                        <Form.Control
                          className='inline'
                          disabled={userData?.memberof !== '4'}
                          value={userData?.othermember}
                          onChange={handleChange('othermember')}
                        />
                        <Form.Check
                          inline
                          style={{ marginLeft: '5px' }}
                          label='Not applicable'
                          name='member'
                          value='5'
                          type={'radio'}
                          id={'member'}
                          checked={userData?.memberof === '5'}
                          onChange={handleChange('memberof')}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-5'>
                  <Col>
                    <Form.Group className='form-group-check'>
                      <Form.Label>
                        Do you have any food restrictions? <b>*</b>
                      </Form.Label>

                      <div className='selection'>
                        <Form.Check
                          inline
                          label='Yes'
                          name='food_restrictions'
                          value='1'
                          type={'radio'}
                          id={'food_restrictions'}
                          checked={userData?.foodrestriction === '1'}
                          onChange={handleChange('foodrestriction')}
                        />
                        <Form.Control
                          className='inline'
                          placeholder='Please Specify'
                          disabled={userData?.foodrestriction !== '1'}
                          value={userData?.specificrestrict}
                          onChange={handleChange('specificrestrict')}
                        />
                        <Form.Check
                          inline
                          style={{ marginLeft: '7px' }}
                          label='None'
                          name='food_restrictions'
                          value='0'
                          type={'radio'}
                          id={'food_restrictions'}
                          checked={userData?.foodrestriction === '0'}
                          onChange={handleChange('foodrestriction')}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <h4>Registration information</h4>

                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label className='for-file'>
                        Attach your Valid LGU ID <b>*</b>
                      </Form.Label>
                      <div className='form-file'>
                        <Form.Control
                          accept='image/png, image/jpeg'
                          onChange={handleFileUpload}
                          id='formfile'
                          type='file'
                        />
                        <Form.Label htmlFor='formfile'>Upload File</Form.Label>
                        <span>{userData?.id?.name || 'No file uploaded'}</span>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col className='col-12'>
                    <Form.Group className='form-group-check'>
                      <Form.Label className='for-file mb-3 mt-3'>
                        Are you the local chief executive? <b>*</b>
                      </Form.Label>
                      <div className='selection'>
                        <Form.Check
                          inline
                          label='Yes'
                          name='chief'
                          id={'chief'}
                          type={'radio'}
                          value='1'
                          checked={userData?.chief === '1'}
                          onChange={handleChange('chief')}
                        />
                        <Form.Check
                          inline
                          label='No'
                          name='chief'
                          type={'radio'}
                          id={'chief'}
                          value='0'
                          checked={userData?.chief === '0'}
                          onChange={handleChange('chief')}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col></Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group-check'>
                      <Form.Label className='for-file mb-3'>
                        Are you the authorized representative of your local government unit?{' '}
                        <b>*</b>
                      </Form.Label>
                      <div className='selection'>
                        <Form.Check
                          inline
                          label='Yes'
                          name='representative'
                          type={'radio'}
                          id={'representative'}
                          value='1'
                          checked={userData?.representative === '1'}
                          onChange={handleChange('representative')}
                        />
                        <Form.Check
                          inline
                          label='No'
                          name='representative'
                          type={'radio'}
                          id={'representative'}
                          value='0'
                          checked={userData?.representative === '0'}
                          onChange={handleChange('representative')}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group-check'>
                      <Form.Label className='for-file mb-3'>
                        I am allowing the Department of Interior and Local Government, and their Ease of Doing Business
                        Forum partners to contact you for updates and promotional activities?{' '}
                        <b>*</b>
                      </Form.Label>
                      <div className='selection'>
                        <Form.Check
                          inline
                          label='Yes'
                          name='emailandpromotion'
                          type={'radio'}
                          id={'emailandpromotion'}
                          value='1'
                          checked={userData?.promotion === '1'}
                          onChange={handleChange('promotion')}
                        />
                        <Form.Check
                          inline
                          label='No'
                          name='emailandpromotion'
                          type={'radio'}
                          id={'emailandpromotion'}
                          value='0'
                          checked={userData?.promotion === '0'}
                          onChange={handleChange('promotion')}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row> */}

                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group-check'>
                      <Form.Label className='for-file mb-3'>
                        Are you the business permit licensing officer (BPLO)? <b>*</b>
                      </Form.Label>
                      <div className='selection'>
                        <Form.Check
                          inline
                          label='Yes'
                          name='isOfficer'
                          type={'radio'}
                          id={'isOfficer'}
                          value='1'
                          checked={userData?.isOfficer === '1'}
                          onChange={handleChange('isOfficer')}
                        />
                        <Form.Check
                          inline
                          label='No'
                          name='isOfficer'
                          type={'radio'}
                          id={'isOfficer'}
                          value='0'
                          checked={userData?.isOfficer === '0'}
                          onChange={handleChange('isOfficer')}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-4'>
                  <Col>
                    <Form.Group className='form-group-check'>
                      <Form.Label className='for-file mb-3'>
                        Are you the Building Official? <b>*</b>
                      </Form.Label>
                      <div className='selection'>
                        <Form.Check
                          inline
                          label='Yes'
                          name='is_building_official'
                          type={'radio'}
                          id={'is_building_official'}
                          value='1'
                          checked={userData?.buildingofficial === '1'}
                          onChange={handleChange('buildingofficial')}
                        />
                        <Form.Check
                          inline
                          label='No'
                          name='is_building_official'
                          type={'radio'}
                          id={'is_building_official'}
                          value='0'
                          checked={userData?.buildingofficial === '0'}
                          onChange={handleChange('buildingofficial')}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className='submit-container'>
                    <Button onClick={handleRegister} variant='primary' className='register'>
                      Register Now!
                    </Button>
                    <Button onClick={handleCancel} variant='primary' className='cancel'>
                      Cancel
                    </Button>
                    <Button onClick={handleClear} variant='primary' className='clear'>
                      Clear Fields
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <div></div>
    </>
  );
};

export default PageTwo;
